<template>
  <div class="commonCode">
    <div class="commonCode-t"><img src="./img/banner.png" alt="" /></div>
    <div class="commonCode-b">
      <div class="commonCode-b-body">
        <div class="title">
          <div class="title-l">
            <span>仅限本人使用</span>
          </div>
          <div class="title-r" @click="refresh">
            <img src="./img/refresh.png" alt="" />
          </div>
        </div>
        <div class="code">
          <!-- <vue-qr :text="imgUrl" :size="size"></vue-qr> -->
          <img :src="imgUrl" alt="" />
        </div>
        <div class="info">
          <div class="info-l"><img src="./img/info.png" alt="" /></div>
          <div class="info-r">如扫码失败，请调亮手机屏幕亮度</div>
        </div>
        <div class="rz-tag">
          <div v-if="auditDetail.isTalent === true" class="tag-text">已认证人才</div>
          <div v-if="auditDetail.isMember === true" class="tag-text">已认证工会会员</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import vueQr from "vue-qr";
import { getCodeUrl, checkIsAudit } from "./api.js";
import { gloabalCount } from "@/utils/common";

export default {
  // components: { vueQr },
  data() {
    return {
      // size: "",
      imgUrl: "",
      status:0,
      auditDetail: {
        isMember: null,
        isTalent: null
      }
    };
  },
  computed: {
    // communityId() {
    //   return this.$store.state.communityId;
    // },
    userId() {
      return this.$store.state.userId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
    // roomId() {
    //   return this.$store.state.houseId;
    // },
    // tenantId() {
    //   return this.$store.state.tenantId;
    // },
    // assetId() {
    //   return this.$store.state.assetId;
    // },
  },
  async created() {
    // this.size = (document.documentElement.clientWidth / 375) * 211;
    await this.checkIsAudit();
    await this.getCode();
  },
  mounted() {
    gloabalCount("", 89, 1);
  },
  methods: {
   
    checkIsAudit() {
      this.$axios
        .get(checkIsAudit, {
          params: {
            userId: this.userId,
            tenantId:this.tenantId
          },
        })
        .then((res) => {
          if(res.code==200){
            this.status=res.data;
            Object.keys(this.auditDetail).forEach(k => {
              this.auditDetail[k] = res.data[k];
            });
          }
        });
    },
    async getCode() {
      let params = {
        page: "pages/targetUrl/index",
        scene: `4&7&param:${JSON.stringify({ userId: this.userId })}`,
      };
      let res = await this.$axios.post(getCodeUrl, params);
      if (res.code === 200) {
        this.imgUrl = res.data;
      }
    },
    refresh() {
      this.getCode();
    },
  },
};
</script>
<style lang="less" scoped>
.commonCode {
  box-sizing: border-box;
  min-height: 100%;
  padding-bottom: 100px;
  background: rgba(0, 0, 0, 0.04);
  .commonCode-t {
    height: 320px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .commonCode-b-body {
    z-index: 99;
    position: relative;
    width: 686px;
    height: 838px;
    background: #ffffff;
    box-shadow: 0px 0px 32px 0px rgba(144, 144, 144, 0.15);
    border-radius: 16px;
    padding: 40px 24px 0;
    box-sizing: border-box;
    margin: -100px auto 0;
    .title {
      display: flex;
      justify-content: space-between;
      align-content: center;
      .title-l {
        display: flex;
        align-items: center;
        font-size: 38px;
        font-weight: 600;
        color: #1a1c34;
        line-height: 52px;
        .tag {
          margin-left: 10px;
          padding: 8px 15px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 24px;
          color: #007eff;
          line-height: 34px;
          text-align: left;
          font-style: normal;
          border: 2px solid #007eff;
          border-radius: 40px;
        }
      }
      .title-r {
        width: 36px;
        height: 36px;
        img {
          vertical-align: middle;
          overflow: hidden;
          width: 100%;
          height: 100%;
        }
      }
    }
    .code {
      width: 422px;
      height: 422px;
      margin: 102px auto 78px;
      > img {
        width: 100%;
        height: 100%;
        overflow: hidden;
        vertical-align: middle;
      }
    }
    .info {
      display: flex;
      padding-left: 84px;
      align-content: center;
      .info-l {
        width: 24px;
        height: 32px;
        margin-right: 14px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .info-r {
        font-size: 28px;
        font-weight: 400;
        color: #a8a8a8;
        line-height: 40px;
      }
    }
  }
}
.rz-tag {
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0px 20px;
  margin-top: 20px;
  .tag-text {
    box-sizing: border-box;
    flex-shrink: 0;
    color: #007EFF;
    font-size: 24px;
    height: 34px;
    padding: 0px 10px;
    border: 1px solid #007EFF;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    margin: 0px 8px;
    background-color: rgba(0, 126, 255, 0.10);
  }
}
</style>
